import "./App.css";
import { HashRouter, Route, Switch } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Drawer from "./components/Drawer";

function App() {
  return (
    <div className="App">
      <header className="App-header"></header>
      <Drawer />
      <HashRouter>
        <Switch>
          <Route exact from="/" render={() => <Home />} />
          <Route exact from="/contact" render={() => <Contact />} />
          <Route exact from="/about" render={() => <About />} />
        </Switch>
      </HashRouter>
    </div>
  );
}

export default App;
