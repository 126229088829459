import React from "react";

const Home = (props) => {
  return (
    <div>
      <h3>Home</h3>
      <typography className="maintext">Home Page</typography>
    </div>
  );
};

export default Home;
